const ApiService = require("/Users/patrickbittner/plentymarkets/5.0.2/plugin-ceres/resources/js/src/app/services/ApiService.js");

Vue.component("finder", {
    template: "#vue-finder",
    props:{
        category1: [Number, String],
        category2: [Number, String],
        category3: [Number, String],
        category4: [Number, String],
        category5: [Number, String]
    },
    data: () => ({
        categories: [],
        category: 0,
        brands: [],
        brand: 0,
        models: [],
        model: 0,
        seriesList: [],
        series: 0,
        engines: [],
        engine: 0,
        loading: false,
        redirectLoading: false
    }),
    created() {
        this.indexCategories();
    },
    methods: {

        indexCategories(){
            this.loading = true;

            this.categories = [];
            this.category = 0;
            this.brands = [];
            this.brand = 0;
            this.models = [];
            this.model = 0;
            this.seriesList = [];
            this.series = 0;
            this.engines = [];
            this.engine = 0;

            ApiService.get("/rest/turboservice24/categories")
                .done(response =>
                {
                    this.categories = response;

                    if(this.category1){
                        this.category = this.category1;
                        this.indexBrands();
                    }

                    this.loading = false;
                });
        },
        indexBrands(){
            this.loading = true;

            this.brands = [];
            this.brand = 0;
            this.models = [];
            this.model = 0;
            this.seriesList = [];
            this.series = 0;
            this.engines = [];
            this.engine = 0;

            ApiService.get("/rest/turboservice24/categories", {parentId: this.category})
                .done(response =>
                {
                    this.brands = response;

                    if(this.category2){
                        this.brand = this.category2;
                        this.indexModels();
                    }
                    this.loading = false;
                });
        },
        indexModels(){
            this.loading = true;

            this.models = [];
            this.model = 0;
            this.seriesList = [];
            this.series = 0;
            this.engines = [];
            this.engine = 0;

            ApiService.get("/rest/turboservice24/categories", {parentId: this.brand})
                .done(response =>
                {
                    this.models = response;

                    if(this.category3){
                        this.model = this.category3;
                        this.indexSeriesList();
                    }
                    this.loading = false;
                });
        },
        indexSeriesList(){
            this.loading = true;

            this.seriesList = [];
            this.series = 0;
            this.engines = [];
            this.engine = 0;

            ApiService.get("/rest/turboservice24/categories", {parentId: this.model})
                .done(response =>
                {
                    this.seriesList = response;
                    if(this.category4){
                        this.series = this.category4;
                        this.indexEngines();
                    }
                    this.loading = false;
                });
        },
        indexEngines(){
            this.loading = true;

            this.engines = [];
            this.engine = 0;

            ApiService.get("/rest/turboservice24/categories", {parentId: this.series})
                .done(response =>
                {
                    this.engines = response;
                    if(this.category5){
                        this.engine = this.category5;
                    }
                    this.loading = false;
                });
        },


        redirect(){
            this.loading = true;
            this.redirectLoading = true;

            if(this.engine > 0){
                const item = this.engines.find(element => element.id == this.engine);
                window.location.href = "/"+item.url;

            } else if(this.series > 0){
                const item = this.seriesList.find(element => element.id == this.series);
                window.location.href = "/"+item.url;

            } else if(this.model > 0){
                const item = this.models.find(element => element.id == this.model);
                window.location.href = "/"+item.url;

            } else if(this.brand > 0){
                const item = this.brands.find(element => element.id == this.brand);
                window.location.href = "/"+item.url;

            } else if(this.category > 0){
                const item = this.categories.find(element => element.id == this.category);
                window.location.href = "/"+item.url;

            } else {
                this.loading = false;
                this.redirectLoading = false;
            }
        }
    }
});

